@font-face {
  font-family: "VisbyRoundCF";
  src: url("../assets/fonts/VisbyRoundCF-ExtraLight.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "VisbyRoundCF";
  src: url("../assets/fonts/VisbyRoundCF-ExtraLight.otf") format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "VisbyRoundCF";
  src: url("../assets/fonts/VisbyRoundCF-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "VisbyRoundCF";
  src: url("../assets/fonts/VisbyRoundCF-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "VisbyRoundCF";
  src: url("../assets/fonts/VisbyRoundCF-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "VisbyRoundCF";
  src: url("../assets/fonts/VisbyRoundCF-DemiBold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "VisbyRoundCF";
  src: url("../assets/fonts/VisbyRoundCF-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "VisbyRoundCF";
  src: url("../assets/fonts/VisbyRoundCF-ExtraBold.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "VisbyRoundCF";
  src: url("../assets/fonts/VisbyRoundCF-Heavy.otf") format("opentype");
  font-weight: 900;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "VisbyRoundCF";
  background-color: #edeef2;
  color: #171717;
  text-align: start;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_container {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 48px;
  padding-bottom: 24px;
  margin-bottom: 32px;
}

.img_box {
  width: 60px;
  height: 60px;
}

.img_box img {
  width: 100%;
  height: 100%;
}

.main_box {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.yap_duck_box {
  position: relative;
  margin-bottom: 40px;
  width: 224px;
  height: 240px;
}

.yap_duck_box img {
  width: 100%;
  height: 100%;
}

.yap_title_box {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.yap_title_box h1 {
  font-size: 100px;
  line-height: 80px;
  margin: 20px 0;
  font-weight: bold;
  font-weight: 900;
  letter-spacing: -5%;
  margin-bottom: 36px;
}

.yap_title_box p {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
}

.buttons {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.buttons a img {
  width: 240px;
  height: 80px;
}

.footer_box {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 32px;
  padding-right: 32px;
}

.footer_1 {
  display: flex;
  column-gap: 32px;
  font-size: 14px;
  flex-wrap: wrap;
}

.footer_1 a {
  color: #171717;
  text-decoration: none;
  font-size: 19px;
  font-weight: 600;
  padding-top: 24px;
  padding-bottom: 24px;
}

.footer_2 {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.footer_2 img {
  width: 48px;
  height: 48px;
}

.overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
  box-sizing: border-box;
  z-index: -1;
}

.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/Mask group.png");
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: -10;
}

.op_container_box {
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 60px;
  padding-top: 60px;
}

.op_header_box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: start;
}

.op_header_box p {
  font-weight: 700;
  font-size: 56px;
  line-height: 67.2px;
  margin: 0px 0px;
}

.op_inner_container_body {
  position: relative;
  padding-top: 24px;
}

.op_inner_container_box {
  position: relative;
  padding-bottom: 40px;
}

.op_inner_container_heading_box {
  position: relative;
  padding-bottom: 8px;
  display: flex;
  justify-content: start;
  align-items: start;
}
.op_inner_container_heading_box p {
  font-weight: 700;
  font-size: 24px;
  margin: 0px 0px;
}

.op_inner_container_para_box {
  position: relative;
  display: flex;
  justify-content: start;
  text-align: start;
  flex-direction: column;
}

.op_inner_container_para_box p {
  font-weight: 600;
  opacity: 60%;
  font-size: 20px;
  margin: 0 0;
}

@media (max-width: 685px) {
  .op_container_box {
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .op_header_box {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: start;
  }

  .op_header_box p {
    font-weight: 600;
    font-size: 36px;
    line-height: 43.2px;
    margin: 0px 0px;
  }

  .op_inner_container_body {
    position: relative;
    padding-top: 24px;
  }

  .op_inner_container_box {
    position: relative;
    padding-bottom: 40px;
  }

  .op_inner_container_heading_box {
    position: relative;
    padding-bottom: 8px;
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .op_inner_container_heading_box p {
    font-weight: 700;
    font-size: 20px;
    margin: 0px 0px;
  }

  .op_inner_container_para_box {
    position: relative;
    display: flex;
    justify-content: start;
    text-align: start;
    flex-direction: column;
  }

  .op_inner_container_para_box p {
    font-weight: 600;
    opacity: 60%;
    font-size: 16px;
    margin: 0 0;
  }

  .img_box {
    width: 48px;
    height: 48px;
  }

  .header_box {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    /* margin-bottom: 120px; */
  }

  .footer_1 {
    display: flex;
    column-gap: 16px;
    font-size: 14px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer_1 a {
    color: #171717;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    padding-top: 12px;
    padding-bottom: 0px;
  }

  .yap_duck_box {
    position: relative;
    margin-bottom: 20px;
    width: 112px;
    height: 120px;
  }

  .yap_title_box h1 {
    font-size: 48px;
    line-height: 48px;
    align-items: center;
    text-align: center;
    width: 180px;
    height: 96px;
    margin: 0 0;
    font-weight: bold;
    font-weight: 900;
    letter-spacing: -5%;
    margin-bottom: 0px;
  }

  .yap_title_box p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/Mask group-mobile.png");
    background-size: cover;
    background-position: center;

    z-index: -10;
  }
  .buttons a img {
    width: 180px;
    height: 60px;
  }
}
